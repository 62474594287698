<template>
  <div class="container">
    <div id ='loader' v-if="is_loading"></div>
    <div v-else>
      <div class="page-progress-wrap">
        <h3>{{ !_.isEmpty(dataDetails) ? dataDetails.progress_bar.title : '' }}</h3>
        <div class="progress-box">
          <img :src="!_.isEmpty(dataDetails) ? dataDetails.progress_bar.progress_box[0].image_link: ''" alt="">
          <span>Input</span>
        </div>
        <div class="progress-box">
          <img :src="!_.isEmpty(dataDetails) ? dataDetails.progress_bar.progress_box[1].image_link: ''" alt="">
          <span>Confirm</span>
        </div>
        <div class="done-box">
          <img :src="!_.isEmpty(dataDetails) ? dataDetails.progress_bar.progress_box[2].image_link: ''" alt="">
          <span>Done</span>
        </div>
      </div>
      <form id="form">
        <div class="receiver-box justify-between">
          <h3>{{ !_.isEmpty(dataDetails) ? dataDetails.mobile_no_text : '' }}</h3>
          <span>{{ !_.isEmpty(dataDetails) ? '+' + dataDetails.country_code : '' }} {{ !_.isEmpty(dataDetails) ? dataDetails.receiver_number : '' }}</span>
        </div>
        <div class="package-section" v-if="service==='internet_topup'">
          <h4>{{ !_.isEmpty(dataDetails) ? dataDetails.package_section_title : '' }}</h4>
          <ul>
            <li v-for="packages in dataDetails.packages">
              <label class="package">
                <input type="radio" name="datapack_id" id="package_58" value="58">
                <div class="package-box"  @click="setPackageId(packages.id,packages.deductible_amount,packages.productSalesValueWithCommissionAndCharge,packages.productChargeRate,packages.name,packages.deductible_amount?packages.deductible_amount:packages.productSalesValueAfterCommission)">
                  <h4><strong>{{ packages.name }}</strong>RM {{ packages.deductible_amount ? packages.deductible_amount : packages.productSalesValueAfterCommission }}</h4>
                  <div class="checked-icon"><img :src="pictureBaseUrl+'/digi/images/checked-black.svg'" alt="checked"></div>
                </div>
              </label>
            </li>
          </ul>
        </div>
        <div class="package-section" v-else>
          <h4>{{ !_.isEmpty(dataDetails) ? dataDetails.package_section_title : '' }}</h4>
          <ul>
            <li v-for="packages in dataDetails.packages">
              <label class="package">
                <input type="radio" name="datapack_id" id="package_58" value="58">
                <div class="package-box"  @click="setPackageId(packages.valueInProductCurrency,packages.valueInBaseCurrencyAfterCommission,packages.productSalesValueWithCommissionAndCharge,packages.productChargeRate,'',packages.valueInBaseCurrencyAfterCommission)">
                  <h4><strong>{{ !_.isEmpty(dataDetails) ? dataDetails.currency_text : '' }} {{packages.valueInProductCurrency}} </strong>RM {{ packages.valueInBaseCurrencyAfterCommission }}</h4>
                  <div class="checked-icon" v-if="!validTopupAmount"><img :src="pictureBaseUrl+'/digi/images/checked-black.svg'" alt="checked"></div>
                </div>
              </label>
            </li>
          </ul>
        </div>
        <div class="other-amount" v-if="service==='talktime_topup' &&  operatorId ==1">
          <label class="form-label" >{{dataDetails.others}} ({{ dataDetails. currency_text}})</label>
          <input type="number" v-validate="{ min_value: 20, max_value: 1000 }" name="other_amount" id="other_amount" v-model="packageAmount"  step="1" class="form-control"  placeholder="20 to 1000">
        </div>
        <br>
        <span style="color: red;" v-if="operatorId==1">{{ dataDetails.topup_cap_msg }}</span>
        <button type="button" id="next" class="btn justify-center"
                data-package_selection_error="আপনি প্যাকেজ সিলেক্ট করেননি।" @click="submit">
          {{ dataDetails.next_btn_text }} <span class="arrow-icon"></span>
        </button>
      </form>
      <div id="wrong_message" v-if="packageSelectionError">
        <div style="text-align:center; color:red;">
          {{ !_.isEmpty(dataDetails) ? dataDetails.package_selection_error : '' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { saveUserJourney } from './../../../services/api/saveUserJourney'
import { updateEvent } from '@/services/api/vivr-engine-helper'
import { gtmDataPush } from '../../../../public/front/js/gtmHepler'
export default {
  name: 'topUpPackages',
  data () {
    return {
      apiUrl: 'content/',
      dataDetails: {},
      pictureBaseUrl: process.env.VUE_APP_MAIN_URL,
      receiverNumber: sessionStorage.getItem('receiverMsisdn'),
      service: '',
      is_loading: true,
      receiverOperatorId: sessionStorage.getItem('receiverOperatorId') ?? '1',
      receiverCountry: sessionStorage.getItem('receiverCountry') ?? 'bn',
      packageAmount: '',
      msisdn: localStorage.getItem('msisdn') ?? '',
      datapackId: '',
      validTopupAmount: false,
      operatorId: localStorage.getItem('operatorId'),
      languageId: sessionStorage.getItem('language'),
      sessionId: sessionStorage.getItem('sessionId'),
      packageSelectionError: null
    }
  },
  watch: {
    packageAmount (value) {
      if (value.length > 1) {
        this.validTopupAmount = true
      }
    }
  },
  mounted () {
    this.init()
    gtmDataPush(null, null, this.receiverNumber)
  },
  methods: {
    async init () {
      this.service = this.$route.params.type
      sessionStorage.setItem('service', this.service)
      if (this.$route.params.type === 'talktime_topup') {
        this.apiUrl += 'talktime-package-page'
      } else {
        this.apiUrl += 'internet-package-page'
      }
      this.apiUrl = `${this.apiUrl}`
      await this.getData().then(response => {
        this.dataDetails = response.data
        this.is_loading = false
      })

      const pageID = this.dataDetails.prompt.pageID
      const eventID = this.dataDetails.prompt.eventID
      updateEvent(pageID, eventID)
      saveUserJourney(this.operatorId, 'package_page', this.msisdn, this.languageId, this.sessionId)
    },
    getData () {
      return new Promise((resolve, reject) => {
        axios
          .post(this.apiUrl, {
            operatorId: this.operatorId,
            receiverNumber: this.receiverNumber,
            receiverOperatorId: this.receiverOperatorId,
            receiverCountry: this.receiverCountry,
            languageId: this.languageId
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
          })
      })
    },
    setPackageId (datapackId, price, priceWithCommision, chargeRate, name = null, packageBasePrice) {
      if (this.service === 'talktime_topup') {
        sessionStorage.setItem('packageAmount', datapackId)
        this.packageAmount = ''
      } else {
        this.datapackId = datapackId
        sessionStorage.setItem('datapackId', datapackId)
      }
      this.datapackId = datapackId
      sessionStorage.setItem('packagePrice', price)
      if (this.operatorId === '2') {
        sessionStorage.setItem('priceRmWithCommision', priceWithCommision)
        sessionStorage.setItem('chargeRate', chargeRate)
        sessionStorage.setItem('packageName', name)
        sessionStorage.setItem('packageBasePrice', packageBasePrice)
      }
    },
    submit () {
      if (this.packageAmount !== '') {
        sessionStorage.setItem('packageAmount', this.packageAmount)
        sessionStorage.removeItem('packagePrice')
        if (this.packageAmount < 20 || this.packageAmount > 1000) {
          this.validTopupAmount = false
          this.$toaster.error('Topup Amount Must be Between 20tk to 100tk')
        }
      }
      if (this.datapackId || this.packageAmount) {
        this.$validator.validateAll().then((result) => {
          if (result) {
            if (this.msisdn) {
              this.$router.push({ name: 'package-review-page', params: { service: this.service } })
            } else {
              this.$router.push({ name: 'input-msisdn-page', params: { service: this.service } })
            }
          }
        })
      } else {
        this.packageSelectionError = true
      }
    }
  }
}
</script>

<style scoped>

</style>
